/*
* @Author: Jeff Shields
* @Date:   2019-03-31 07:42:46
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2019-03-31 07:43:13
*/

.loading-rn {
    display: none;
}


#embeded iframe,
.embeded iframe {
    width: 100%;
}
